import React, { useState } from "react";

import CollapseIcon from "../../icons/collapse.svg";
import ExpandIcon from "../../icons/expand.svg";

const Amplification = ({ children }) => {
  const [open, setOpen] = useState(false);

  return (
    <span className="bg-theme2-bg-3 px-1 rounded-lg border-y-2-theme1-bg-2">
      <button
        onClick={() => {
          setOpen(!open);
        }}
        className="touch-manipulation"
        aria-label="toggle amplification"
      >
        <img src={open ? CollapseIcon : ExpandIcon} alt="" className="inline" />
      </button>
      {open && children}
    </span>
  );
};

export default Amplification;
